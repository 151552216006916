.App {
  text-align: center;
  background-repeat: no-repeat;
  background-color: #000000;
  background-attachment: fixed;
  background-position: center;
  background-size: auto 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: lifecraft;
  margin-bottom: 40px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: lifecraft;
  src: url(../public/LifeCraft_Font.ttf);
}

.lifecraft {
  font-family: lifecraft;
  font-size: 72px;
  color: white;
}

.lifecraft-red {
  font-family: lifecraft;
  font-size: 72px;
  color: #9f2305;
}

.player-card-grid{
  display: inline-flex;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
}

.player-card {
  font-family: lifecraft;
  height: 300px;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.player-card.card-shadow{
  border: none;
  box-shadow: 0 2px 5px 0 #000000;
}

.player-card-header {
  font-size: 1.5rem;
  margin-bottom: .5rem;
  float: left;
}

.player-card-body {
  font-size: .9rem;
}

.player-card-footer {
  margin-top: 1rem;
}

.card {
  margin-bottom: 2rem;
  border-color: lightgray;
  color: white;
  font-family: lifecraft;
  background-color: black;
  filter: none;
  -webkit-filter: brightness(90%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.card:hover {
  filter: none;
  -webkit-filter: brightness(110%);
}

.card-body {
  border-top: 1px solid lightgray;
  background-color: black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.class-icon {
  border-radius: 5px;
  margin-right: 10px
}

.profession-icon {
  border-radius: 5px;
  margin-right: 10px
}

.medium-logo {
  width: 300px;
  height: 300px;
}

.small-logo {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-left: 20px;
}

.red {
  color: #9f2305;
}

.nav {
  background-color: #333;
  color: #9f2305;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li:hover {
  color: #511000;
}

.nav li.active {
  color: white;
}

.twitch-embed {
  height: 485px;
  width: 642px;
}

.twitch-embed-large {
  height: 725px;
  width: 1282px;
}

.footer-font {
  font-family: lifecraft;
  font-size: 24px;
}
